<template>
  <!-- 重构 - 结算管理 - 生成结算单（收款） -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goReturn()">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">生成旧结算单（收款）</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="结算信息" class="ts"></el-form-item>
          <!-- <el-form-item label="选择合同" prop="contractId" class="tsWidth">
            <el-select
              size="small"
              v-model="ruleForm.contractId"
              placeholder="请选择合同"
              ><el-option
                v-for="item in fdNewContractLists"
                :key="item.contractId"
                :label="item.contractName"
                :value="item.contractId"
              ></el-option>
            </el-select>
            <el-button type="text" @click="previewContract">预览合同</el-button>
          </el-form-item> -->
          <el-form-item label="结算单号" prop="billNo">
            <el-input
              size="small"
              v-model="ruleForm.billNo"
              :disabled="this.fatherData.addEdit == 'edit'"
              placeholder="请输入结算单号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="国家补贴标准" class="btbzWitch">
            <el-table
              :data="ruleForm.fdNewProjectBOList"
              :highlight-current-row="true"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              class="tb-edit"
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              ></el-table-column>
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="级别"
                align="left"
                show-overflow-tooltip
                prop="subsidyLevel"
                min-width="80"
                ><template slot-scope="scope">
                  {{ scope.row.subsidyLevel ? scope.row.subsidyLevel : "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="国家补贴标准(元)"
                align="left"
                show-overflow-tooltip
                prop="countrySubsidyStandard"
                min-width="120"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.countrySubsidyStandard"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.countrySubsidyStandard = zF.oninput2(
                        scope.row.countrySubsidyStandard,
                        2
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                    :style="disabled1 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled1 ? 'display: block;' : ''">{{
                    scope.row.countrySubsidyStandard
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="canSettleNum"
                min-width="100"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.settleNum"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.settleNum = zF.negativeTurnjust1(
                        scope.row.settleNum
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.settleNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="总课时"
                align="left"
                show-overflow-tooltip
                prop="totalPeriod"
                min-width="100"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.totalPeriod"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.totalPeriod = zF.negativeTurnjust1(
                        scope.row.totalPeriod
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.totalPeriod }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="公共课时"
                align="left"
                show-overflow-tooltip
                prop="publicLessonNum"
                min-width="100"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.publicLessonNum"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.publicLessonNum = zF.negativeTurnjust1(
                        scope.row.publicLessonNum
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                  ></el-input>
                  <span>{{ scope.row.publicLessonNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="平台技术服务费（元）"
                align="left"
                show-overflow-tooltip
                prop="platformTechnicalFee"
                min-width="150"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.platformTechnicalFee"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.platformTechnicalFee = zF.oninput2(
                        scope.row.platformTechnicalFee,
                        2
                      )
                    "
                    @blur="platformCoursesCost(scope.$index, 'list')"
                    :style="disabled2 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled2 ? 'display: block;' : ''">{{
                    scope.row.platformTechnicalFee
                  }}</span></template
                >
              </el-table-column>
              <el-table-column
                label="课程服务费（元）"
                align="left"
                show-overflow-tooltip
                prop="courseFee"
                min-width="120"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.courseFee"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.courseFee = zF.oninput2(scope.row.courseFee, 2)
                    "
                    @blur="platformCoursesCost(scope.$index, 'list')"
                    :style="disabled2 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled2 ? 'display: block;' : ''">{{
                    scope.row.courseFee
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="平台服务费（元）"
                align="left"
                show-overflow-tooltip
                prop="platformTotalFee"
                min-width="120"
                ><template slot-scope="scope">
                  <el-input
                    size="small"
                    v-model="scope.row.platformTotalFee"
                    placeholder="请输入"
                    @keyup.native="
                      scope.row.platformTotalFee = zF.oninput2(
                        scope.row.platformTotalFee,
                        2
                      )
                    "
                    @blur="algorithm(scope.$index, 'list')"
                    :style="disabled3 ? 'display: none;' : ''"
                  ></el-input>
                  <span :style="disabled3 ? 'display: block;' : ''">{{
                    scope.row.platformTotalFee
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item
            label="收费标准"
            prop="chargesSettlement"
            class="sfbzWidth"
          >
            <el-input
              type="textarea"
              maxlength="1000"
              resize="none"
              placeholder="请输入收费标准"
              show-word-limit
              v-model="ruleForm.chargesSettlement"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" class="sfbzWidth">
            <el-input
              type="textarea"
              maxlength="500"
              placeholder="请输入备注"
              resize="none"
              show-word-limit
              v-model="ruleForm.remark"
            ></el-input>
          </el-form-item>
          <el-form-item label="收付款账号信息" class="ts"></el-form-item>
          <el-form-item label="付款单位" prop="payerName">
            <el-input
              v-model="ruleForm.payerName"
              placeholder="请输入付款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款单位" prop="payeeName">
            <el-input
              v-model="ruleForm.payeeName"
              placeholder="请输入收款单位"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="payeeBank">
            <el-input
              v-model="ruleForm.payeeBank"
              placeholder="请输入开户银行"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="payeeAccount">
            <el-input
              v-model="ruleForm.payeeAccount"
              placeholder="请输入银行账号"
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item class="operation">
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="goReturn()"
              >返回</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 1)"
              >预览结算单</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              size="small"
              round
              @click="generatePreview('ruleForm', 2)"
              >生成结算单</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看班级明细 - loading -->
    <el-dialog
      title="查看班级明细"
      :visible.sync="dialogVisible1"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert1" style="height: 600px"></div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog
      title="预览合同"
      :visible.sync="dialogVisible3"
      width="900px"
      top="2%"
      center
      ><contractManagementAdd
        :seeTH="true"
        :seeContractId="ruleForm.contractId"
        v-if="dialogVisible3"
      />
    </el-dialog>
    <!-- 预览结算单 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="dialogVisible2"
      width="50%"
      top="2%"
      center
      ><div id="pdf-cert2" style="height: 600px"></div>
    </el-dialog>
    <!-- 上传附件预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="uploadLoding"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div id="pdf-cert3" style="height: 600px"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import { console } from "../../../utils/helper";
export default {
  name: "singleAdministrationChildrenFirstEdit",
  components: {
    contractManagementAdd,
  },
  data() {
    return {
      // 父页面带过的参数
      fatherData: {
        id: "", // 待结算列表无id;结算单管理有id
        type: "", // first-收款结算单;2-付款结算单
        addEdit: "", // add-待结算列表;edit-结算单管理
      },
      // 页面数据
      ruleForm: {
        billId: "", // 结算单id
        billNo: "",
        contractId: "", // 合同
        trainTypeId: "", // 培训类型id
        fdNewProjectBOList: [], // 国家补贴标准列表数据
        chargesSettlement: "", // 收费标准
        remark: "", // 备注
        payerName: "", // 付款单位
        payeeName: "北京晟融信息技术有限公司", // 收款单位
        payeeBank: "中国民生银行股份有限公司北京苏州街支行", // 开户银行
        payeeAccount: "154322354", // 银行账号
        settlementUserCount: 0, // 潜在字段 - 结算人数
        totalLessonNum: 0, // 潜在字段 - 总课时
        openLessonNum: 0, // 潜在字段 - 总公共课时
        payerAmount: 0, // 潜在字段 - 平台服务费（元）
      },
      // 合同下拉数据
      fdNewContractLists: [],
      // 数据校验
      rules: {
        // // 选择合同
        // contractId: [
        //   { required: true, message: "请选择合同", trigger: "change" },
        // ],
        // 结算单号
        billNo: [
          { required: true, message: "请输入结算单号", trigger: "blur" },
        ],
        // 收费标准
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        // 付款单位
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        // 收款单位
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        // 开户银行
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        // 银行账号
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 国家补贴标准列表 - 国家补贴标准（元）是否可以输入
      disabled1: false,
      // 国家补贴标准列表 - 平台技术服务费（元）/ 课程服务费（元）是否可以输入
      disabled2: false,
      // 国家补贴标准列表 - 平台服务费（元）是否可以输入
      disabled3: false,
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 预览合同 - loading
      dialogVisible3: false,
      options: [
        {
          value: true,
          label: "已结清",
        },
        {
          value: false,
          label: "未结清",
        },
      ],
      uploadLoding: false,
      uploadfileList: [],
    };
  },
  created() {
    this.fatherData.id = JSON.parse(this.$route.query.editInfo).id;
    this.fatherData.type = JSON.parse(this.$route.query.editInfo).type;
    this.fatherData.addEdit = JSON.parse(this.$route.query.editInfo).addEdit;
    this.generateSettlementDocument();
  },
  mounted() {},
  methods: {
    // 面包屑 - 返回上个页面
    goReturn() {
      this.$router.push({
        path:
          this.fatherData.addEdit == "edit"
            ? "/web/financialSettlement/settlement/singleAdministration"
            : "/web/financialSettlement/settlement/toBeSettledList",
        query: {
          active: this.fatherData.type,
          refresh: true,
          multipleTable:
            this.fatherData.addEdit == "edit" ? "" : "multipleTable",
        },
      });
    },
    // 预览合同
    previewContract() {
      if (this.ruleForm.contractId) {
        console.log(this.ruleForm.contractId);
        this.dialogVisible3 = true;
      } else {
        this.$message.error("请选择合同");
      }
    },

    // 获取结算单数据
    generateSettlementDocument() {
      if (this.fatherData.addEdit == "add") {
        this.$post("/biz/new/bill/generateOldStatement", {
          projectIds: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.trainTypeId = res.data.trainTypeId; // 培训类型
              this.ruleForm.fdNewProjectBOList = res.data.eduProjectList; // 国家补贴标准数据
              this.ruleForm.settleStandardType = res.data.settleStandardType; // 国家补贴标准数据
              this.ruleForm.settleContent = res.data.settleContent; // 国家补贴标准数据
              this.ruleForm.payerName = res.data.payerName; // 国家补贴标准数据
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard == 0) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].totalPeriod == 0) {
                  this.ruleForm.fdNewProjectBOList[i].totalPeriod = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].publicLessonNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].publicLessonNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].platformTotalFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                }
              }
              if (res.data.fdNewContractList.length) {
                this.fdNewContractLists = res.data.fdNewContractList; // 合同数据
                this.ruleForm.contractId =
                  res.data.fdNewContractList[0].contractId; // 默认赋值合同
              }
            }
          })
          .catch(() => {
            return;
          });
      }
      if (this.fatherData.addEdit == "edit") {
        this.$post("/biz/new/bill/getOldBillInfo", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.fdNewProjectBOList = res.data.fdNewProjectBOList; // 国家补贴标准数据
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard == 0) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].totalPeriod == 0) {
                  this.ruleForm.fdNewProjectBOList[i].totalPeriod = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].publicLessonNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].publicLessonNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].platformTotalFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                }
                // if (this.ruleForm.fdNewProjectBOList[i].settleFinishedNum == 0) {
                //   this.ruleForm.fdNewProjectBOList[i].settleNum =this.ruleForm.fdNewProjectBOList[i].settleNum;
                // } else {
                //   this.ruleForm.fdNewProjectBOList[i].settleNum =this.ruleForm.fdNewProjectBOList[i].settleNum-this.ruleForm.fdNewProjectBOList[i].settleFinishedNum;
                // }
              }
              console.log(res.data.fdNewBill.billId);
              this.ruleForm.trainTypeId = res.data.fdNewBill.trainTypeId; // 培训类型
              this.ruleForm.settlementUserCount =
                res.data.fdNewBill.settlementUserCount; // 潜在字段 - 结算人数
              this.ruleForm.totalLessonNum = res.data.fdNewBill.totalLessonNum; // 潜在字段 - 总课时
              this.ruleForm.openLessonNum = res.data.fdNewBill.openLessonNum; // 潜在字段 - openLessonNum
              this.ruleForm.payerAmount = res.data.fdNewBill.payerAmount; // 潜在字段 - 平台服务费（元）
              this.ruleForm.settleStandardType = res.data.settleStandardType;
              (this.ruleForm.remark = res.data.fdNewBill.remark), // 备注
                (this.ruleForm.billNo = res.data.fdNewBill.billNo), // 结算单号
                (this.ruleForm.billId = res.data.fdNewBill.billId), // 结算单号
                (this.ruleForm.payerName = res.data.fdNewBill.payerName), // 付款单位
                (this.ruleForm.payeeName = res.data.fdNewBill.payeeName), // 收款单位
                (this.ruleForm.payeeBank = res.data.fdNewBill.payeeBank), // 开户银行
                (this.ruleForm.payeeAccount = res.data.fdNewBill.payeeAccount)(
                  // 银行账号
                  (this.ruleForm.chargesSettlement =
                    res.data.fdNewBill.chargesSettlement)
                )(
                  //
                  (this.ruleForm.settleContent = res.data.settleContent)
                ); //
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 算法('列表当前行的下标','列表当前行的数据');国家补贴标准/结算人数/总课时/公共课 =>计算:平台技术服务费（元）/课程服务费（元）/平台服务费（元）
    algorithm(index, type) {
      if (type == "list") {
        // let lData = [];
        // // for (let i = 0; i < this.fdNewContractLists.length; i++) {
        // //   if (
        // //     this.fdNewContractLists[i].contractId == this.ruleForm.contractId
        // //   ) {
        // //     lData = this.fdNewContractLists[i];
        // //   }
        // // }
        // let htData = JSON.parse(this.ruleForm.settleContent);
        if (this.ruleForm.settleStandardType == "40") {
          // this.ruleForm.fdNewProjectBOList[i].paycount = htData.fixedCost;
          this.ruleForm.fdNewProjectBOList[index].paycount = (
            Number(
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee  == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
            ) +
            Number(this.ruleForm.fdNewProjectBOList[index].courseFee == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].courseFee) +
            Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
          ).toFixed(2);
          console.log('wocao555',  this.ruleForm.fdNewProjectBOList[index].platformTotalFee == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].platformTotalFee);
          console.log('wocao',this.ruleForm.fdNewProjectBOList[index].paycount);
        }
      }
    },
    // 算法('列表当前行的下标','列表当前行的数据');平台技术服务费（元）/课程服务费（元）=>计算:平台服务费（元）
    platformCoursesCost(index, type) {
      if (type == "list") {
        let lData = [];
        if (this.ruleForm.settleStandardType == "40") {
          this.ruleForm.fdNewProjectBOList[index].paycount = (
            Number(
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee  == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
            ) +
            Number(this.ruleForm.fdNewProjectBOList[index].courseFee == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].courseFee) +
            Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee == undefined ? '0' : this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
          ).toFixed(2);
        }
      }
    },

    // 生成/预览 - 结算单按钮;1-预览/2-生成 'add'-添加/编辑
    generatePreview(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hData = {
            billNo: this.ruleForm.billNo,
            trainTypeId: this.ruleForm.trainTypeId, // 培训类型id
            chargesSettlement: this.ruleForm.chargesSettlement, // 收费标准
            remark: this.ruleForm.remark, // 备注
            payerName: this.ruleForm.payerName, // 付款单位
            payeeName: this.ruleForm.payeeName, // 收款单位
            payeeBank: this.ruleForm.payeeBank, // 开户银行
            payeeAccount: this.ruleForm.payeeAccount, // 银行账号
          };
          if (this.ruleForm.billId) {
            hData.billId = this.ruleForm.billId;
          }
          let gjbtbzData = [];
          this.ruleForm.fdNewProjectBOList.forEach((item) => {
            gjbtbzData.push(Object.assign({}, item));
          });
          console.log(gjbtbzData);
          let settlementUserCounts = 0;
          let totalLessonNums = 0;
          let openLessonNums = 0;
          let payerAmounts = 0;
          for (let i = 0; i < gjbtbzData.length; i++) {
            console.log("nb,,,", gjbtbzData[i].paycount);
            if (!gjbtbzData[i].countrySubsidyStandard) {
              gjbtbzData[i].countrySubsidyStandard = 0;
            }
            if (!gjbtbzData[i].settleNum) {
              gjbtbzData[i].settleNum = 0;
            }
            if (!gjbtbzData[i].totalPeriod) {
              gjbtbzData[i].totalPeriod = 0;
            }
            if (!gjbtbzData[i].publicLessonNum) {
              gjbtbzData[i].publicLessonNum = 0;
            }
            if (!gjbtbzData[i].platformTechnicalFee) {
              gjbtbzData[i].platformTechnicalFee = 0;
            }
            if (!gjbtbzData[i].courseFee) {
              gjbtbzData[i].courseFee = 0;
            }
            if (!gjbtbzData[i].platformTotalFee) {
              gjbtbzData[i].platformTotalFee = 0;
            }
            if (!gjbtbzData[i].paycount) {
              gjbtbzData[i].paycount = 0;
            }
            settlementUserCounts += Number(gjbtbzData[i].settleNum);
            totalLessonNums += Number(gjbtbzData[i].totalPeriod);
            openLessonNums += Number(gjbtbzData[i].publicLessonNum);
            payerAmounts += Number(gjbtbzData[i].paycount);
          }
          hData.fdNewProjectBOList = gjbtbzData; // 整理好的国家补贴标准列表
          hData.settlementUserCount = settlementUserCounts; // 潜在字段 - 结算人数
          hData.totalLessonNum = totalLessonNums; // 潜在字段 - 总课时
          hData.openLessonNum = openLessonNums; // 潜在字段 - 总公共课时
          hData.payerAmount = payerAmounts.toFixed(2); // 潜在字段 - 平台服务费（元）
          if (type == 1) {
            this.$post("/biz/new/bill/insertOldReview", hData)
              .then((ret) => {
                this.dialogVisible2 = true;
                this.$nextTick(() => {
                  pdf.embed(ret.message, "#pdf-cert2");
                });
              })
              .catch((err) => {
                return;
              });
          } else {
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/bill/insertOldBill", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch((err) => {
                  return;
                });
            } else {
              this.$post("/biz/new/bill/modifyOldBill", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.goReturn();
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                  }
                })
                .catch((err) => {
                  return;
                });
            }
          }
        }
      });
    },
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.uploadLoding = true;
      this.$nextTick(() => {
        pdf.embed(item.fileUrl, "#pdf-cert3");
      });
    },
    reomveExl(index) {
      this.uploadfileList.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="less">
.demo-ruleForm {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 500px;
    .el-input {
      width: 280px;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      width: 100px;
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
  .tb-edit .current-row .el-select + span {
    display: none;
  }
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
</style>
